import React from 'react';
import q from './SmallPopup.module.css'
import {useDispatch, useSelector} from "react-redux";


const SmallPopup = ({children, ppi}) => {

    const show = useSelector(state => state.popup);
    const dispatch = useDispatch();

    const inspect = () => {
        switch (ppi) {
            case 'suc':
                setTimeout(() => {
                    dispatch({type: "HIDE_POPUP"})
                },2300)
                return q.suc;
            case 'war':
                return q.war;
            case 'dan':
                return q.dan;
            default:
                return null
        }
    }

    return (
        <div className={show
            ? [q.popupItem, q.opn, inspect()].join(' ')
            : q.popupItem
        } onClick={e => e.stopPropagation()}>
            <div className={q.popupItemChildren}>
                <div className={q.children}>{children}</div>
                {ppi !== 'suc' && <div onClick={() => dispatch({type: "HIDE_POPUP"})} className={q.ok}> ok </div>}
            </div>
        </div>
    );
};

export default SmallPopup;