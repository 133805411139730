import React, {useEffect, useState} from 'react';
import SmallPopup from "../SmallPopup/SmallPopup";
import {useDispatch, useSelector} from "react-redux";

const LoginForm = () => {

        const dispatch = useDispatch();
        const [ppi, setPpi] = useState('');
        const [popupMessage, setpopupMessage] = useState('');
        const show = useSelector(state => state.popup);


        useEffect(() => {
            console.log(ppi)
        }, [ppi]);


        const [form, setForm] = useState({
            login: '',
            password: ''
        });

        const prep = () => {
            if (form.login === '' || form.password === '') {
                dispatch({type: "SHOW_POPUP"});
                setpopupMessage('Заполнены не все поля')
                setPpi('dan');
                return;
            } else {
                console.log(form);
                setForm({
                    login: '',
                    password: ''
                });
                dispatch({type: "SHOW_POPUP"});
                setpopupMessage('Оотлично !!!');
                setPpi('suc');
            }
        };

        const inputValue = (event) => {
            if (show) dispatch({type: "HIDE_POPUP"});
            if (event.target.name === 'login') {
                setForm({...form, [event.target.name]: Number(event.target.value)})
            } else if (event.target.name === 'password') {
                setForm({...form, [event.target.name]: event.target.value})
            }
        }


        return (
            <div className="form">
                <div className="item form-data">
                    <span>Авторизация</span>
                    <input name="login"
                           type="text"
                           placeholder='Введите логин'
                           value={form.login}
                           onInput={inputValue}
                    />
                    <input name="password"
                           type="password"
                           placeholder='Введите пароль'
                           value={form.password}
                           onInput={inputValue}
                    />
                    <div className="reg_line">
                        <div className="registration">Регистрация</div>
                        <div className="enter" onClick={prep}>Войти</div>
                    </div>
                </div>
                <SmallPopup show={true} ppi={ppi}>
                    {popupMessage}
                </SmallPopup>
            </div>
        );
    }
;

export default LoginForm;