import './App.css';
import React, {useEffect, useState} from "react";
import imgNote from "./img/Note.png"
import LoginForm from "./components/LoginForm/LoginForm";


function App() {
    const url = 'https://prokat.allbiz.by/some.php';
    const [data, setData] = useState(false);
    const [start, setStart] = useState(true);
    const [person, setPerson] = useState(null);


    const user = 293460869;

    useEffect(() => {
        // console.log(person);
    }, [person]);

    function initial() {
        const formData = new FormData();
        formData.append('getRN', user);
        fetch(url, {
            method: 'POST',
            body: formData,
        })
            .then(res => {
                setStart(false);
                // console.log(res.json());
                return res.json();
            })
            .then(res => {
                setPerson(res[0]);
                getData(res[0]);
            })
    }

    if (start) initial();

    function getData(arg) {
        const formData = new FormData();
        formData.append('getData', '');
        formData.append('owner_id', arg.id);
        formData.append('token', arg.access_key);

        fetch(url, {
            method: 'POST',
            body: formData,
        })
            .then(res => res.json())
            .then(data => {
                setData(data)
            })
    }


    return (
        <div className="App">
            <div className="startLogo">
                <div className="logoImg">
                    <img src={imgNote} alt=""/>
                    <span>Прокат</span>
                </div>
                <LoginForm />
            </div>
        </div>
    )
}

export default App;
