import {applyMiddleware, combineReducers, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension/developmentOnly";

const popupReduscer = (state = false, action) => {
    switch (action.type) {
        case "SHOW_POPUP":
            return true;
        case "HIDE_POPUP":
            return false;
        default:
            return state
    }
}


const rootReducer = combineReducers({
    popup: popupReduscer,
});
export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware())
);